// Generated by Framer (36a78eb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as sharedStyle from "../css/qEtUMzkDE";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const cycleOrder = ["NnnqgQ13L"];

const serializationHash = "framer-2Ia5D"

const variantClassNames = {NnnqgQ13L: "framer-v-p7wasm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, icon, id, title, visibility, width, ...props}) => { return {...props, pf3hmyL7U: title ?? props.pf3hmyL7U ?? "Tjänster", pVsPDEUXR: visibility ?? props.pVsPDEUXR, R42btkVs4: icon ?? props.R42btkVs4 ?? "Home"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;visibility?: boolean;icon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pf3hmyL7U, pVsPDEUXR, R42btkVs4, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "NnnqgQ13L", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2Ia5D", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-p7wasm", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"NnnqgQ13L"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-5d7b7448-380a-43e9-8b45-a159c387a2f0, rgb(240, 98, 101)) /* {\"name\":\"Secondary Red\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}}>{pVsPDEUXR && (<motion.div className={"framer-18v7cjp-container"} layoutDependency={layoutDependency} layoutId={"QbS_Devc5-container"}><Material color={"var(--token-80cd1325-a350-405b-932e-8e075517b6cc, rgb(219, 21, 25)) /* {\"name\":\"Red\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={R42btkVs4} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"QbS_Devc5"} layoutId={"QbS_Devc5"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-9meii"} data-styles-preset={"qEtUMzkDE"}>Tjänster</motion.p></React.Fragment>} className={"framer-lz5z1t"} data-framer-name={"text"} layoutDependency={layoutDependency} layoutId={"kiMosyxuG"} style={{"--framer-paragraph-spacing": "0px"}} text={pf3hmyL7U} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2Ia5D [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2Ia5D .framer-ro7tui { display: block; }", ".framer-2Ia5D .framer-p7wasm { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: center; overflow: visible; padding: 4px 10px 4px 10px; position: relative; width: min-content; }", ".framer-2Ia5D .framer-18v7cjp-container { flex: none; height: 16px; position: relative; width: 16px; }", ".framer-2Ia5D .framer-lz5z1t { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2Ia5D .framer-p7wasm { gap: 0px; } .framer-2Ia5D .framer-p7wasm > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-2Ia5D .framer-p7wasm > :first-child { margin-left: 0px; } .framer-2Ia5D .framer-p7wasm > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 86
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"pf3hmyL7U":"title","pVsPDEUXR":"visibility","R42btkVs4":"icon"}
 * @framerImmutableVariables true
 */
const FramerjXDOAt1Vp: React.ComponentType<Props> = withCSS(Component, css, "framer-2Ia5D") as typeof Component;
export default FramerjXDOAt1Vp;

FramerjXDOAt1Vp.displayName = "section-tag";

FramerjXDOAt1Vp.defaultProps = {height: 26, width: 86};

addPropertyControls(FramerjXDOAt1Vp, {pf3hmyL7U: {defaultValue: "Tjänster", displayTextArea: false, title: "Title", type: ControlType.String}, pVsPDEUXR: {defaultValue: false, title: "Visibility", type: ControlType.Boolean}, R42btkVs4: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "Home", hidden: undefined, title: "Icon"}} as any)

addFonts(FramerjXDOAt1Vp, [...MaterialFonts, ...sharedStyle.fonts])